import { navigate } from "gatsby";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import LoginContainer from "../components/forms/loginFormContainer";
import { useQueryParam, StringParam } from "use-query-params";
import { AppwriteContext } from "../appwriteContext";
import { toast } from "react-toast";
import { Button } from "luxd";
import { FaDiscord, FaLightbulb, FaTwitch } from "react-icons/fa";
import { MdChevronRight, MdCloudDownload } from "react-icons/md";
import { Error } from "../types";
import { SocialLinks } from "../components/SocialLinks";
import { FormStatus } from "../components/forms/forms";
import { joinDiscord } from "../utils/navigation";
import { discordLink } from "../constants";
import { MixpanelContext } from '../metrics';

const ConfirmPage = (): ReactElement => {
    const [userId] = useQueryParam("userId", StringParam);
    const [secret] = useQueryParam("secret", StringParam);

    const [error, setError] = useState<Error>();
    const [status, setStatus] = useState<FormStatus>();

    const appwrite = useContext(AppwriteContext);
    const mixpanel = useContext(MixpanelContext);

    useEffect(() => {
        if (error) {
            return;
        }
        if (userId && secret) {
            const verify = appwrite.account.updateVerification(userId, secret);
            verify.then(
                resp => {
                    mixpanel.identify(userId);
                    toast.success("Email verified");
                    mixpanel.track('ConfirmEmail');
                },
                err => {
                    toast.error("Email verification failed");
                    setError(err);
                    mixpanel.track('ConfirmEmailFailed');
                }
            );
        } else {
            navigate("/");
        }
    });

    return (
        <LoginContainer>
            {!error && (
                <>
                    <div className="flex flex-col space-y-4 justify-between mx-12 mt-8 text-offWhite items-center">
                        <h1 className="text-2xl font-display text-center">
                            Account setup complete!
                        </h1>
                    </div>
                    <div className="flex flex-col space-y-6 justify-between mx-4 mt-6 text-offWhite">
                        <button
                            className="action"
                            onClick={() =>
                                navigate("/download", { replace: true })
                            }
                        >
                            <div>
                                <MdCloudDownload size={32} />
                            </div>
                            <p className="text-lg text-left">
                                Download and install the Streamlux desktop app
                            </p>
                            <div className="action-arrow">
                                <MdChevronRight size={24} />
                            </div>
                        </button>
                        <button className="action">
                            <div>
                                <FaLightbulb size={32} />
                            </div>
                            <p className="text-lg text-left">
                                Setup your lights, and create animations with
                                the Lux Editor
                            </p>
                            <div className="action-arrow">
                                {/* <MdChevronRight size={24} /> */}
                            </div>
                        </button>
                        <button className="action">
                            <div>
                                <FaTwitch size={32} />
                            </div>
                            <p className="text-lg text-left">
                                Connect your Twitch account, and start
                                streaming!
                            </p>
                            <div className="action-arrow">
                                {/* <MdChevronRight size={24} /> */}
                            </div>
                        </button>
                        <button
                            className="action"
                            onClick={() => window.open(discordLink, "_blank")}
                        >
                            <div>
                                <FaDiscord size={32} />
                            </div>
                            <p className="text-lg text-left">
                                Get support, suggest ideas, and promote your
                                stream in our Discord server.
                            </p>
                            <div className="action-arrow">
                                <MdChevronRight size={24} />
                            </div>
                        </button>
                    </div>
                    <div className="flex flex-col space-y-8 justify-between mx-12 mt-4 text-offWhite">
                        <div className="flex flex-col space-y-4 items-center">
                            <div className="flex flex-row space-x-4 justify-center pt-4 items-center">
                                <Button
                                    icon={<FaDiscord />}
                                    size="normal"
                                    onClick={() => joinDiscord()}
                                >
                                    Join our Discord
                                </Button>
                            </div>
                        </div>

                        <div className="flex flex-row justify-center">
                            <SocialLinks iconClassnames="w-6" />
                        </div>
                    </div>
                </>
            )}
            {error && (
                <div className="flex flex-col px-12 text-lg space-y-4 ">
                    <h1 className="mx-auto text-center mt-4 text-xl font-display text-error h-32 pt-8">
                        Could not verify email. <br /> Error: {error.message}
                    </h1>
                    <Button
                        size="normal"
                        onClick={() => navigate("/", { replace: true })}
                    >
                        Streamlux Home
                    </Button>
                </div>
            )}
        </LoginContainer>
    );
};

export default ConfirmPage;
