import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby";
import React, { PropsWithChildren, ReactElement } from "react";

interface ILoginProps {
    backgroundImage?: string;
}

const LoginContainer = (
    props: PropsWithChildren<ILoginProps>
): ReactElement => {
    const { children, backgroundImage } = props;

    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutLoginForm {
                edges {
                    node {
                        id
                        heading
                        emailFieldLabel
                        passwordFieldLabel
                        forgotPasswordText
                        forgotPasswordUrl
                        signInButtonText
                        childContentfulLayoutLoginFormSubHeadingRichTextNode {
                            childContentfulRichText {
                                html
                            }
                        }
                        backgroundImage {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutLoginForm.edges[0].node;

    const img = backgroundImage ?? `url(${content.backgroundImage.file.url})`;

    return (
        <div
            className="p-0 sm:p-8 flex-grow min-h-screen my-auto flex flex-col items-center"
            style={{
                backgroundImage: img,
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover'
            }}
        >
            <div className="lg:space-y-8 bg-darkGray max-w-xl lg:w-7/12 md:w-9/12 w-full m-auto h-screen sm:h-auto max-h-full shadow-md sm:rounded-lg p-8 overflow-y-auto ">
                <Link to="/">
                    <img
                        src={require("assets/images/logo.svg")}
                        alt="Streamlux logo"
                        className="w-1/2 mx-auto"
                    />
                </Link>
                <div className="rounded-xl">{children}</div>
            </div>
        </div>
    );
};

export default LoginContainer;
